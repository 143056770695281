import { createRouter, createWebHistory } from "vue-router"
import MainPage from "@/views/MainPage.vue"
import { getTitle } from "./getTitle"
import allRoutes from "./pages"
import { getTitleFromNews } from "@/helpers/getTitleFromNews"

export const routes = [
  { path: "/", name: "Home", component: MainPage },
  {
    path: "/news",
    name: "News",
    component: () => import(/*webpackChunkName:"NewsPage"*/ "@/views/NewsPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "NotFound"*/ "@/views/404/NotFound.vue"),
  },
  ...allRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    /*   if (to) {
        return {
          top: 0,
        };
      } */
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0, behavior: "smooth" }, 500))
      })
    )
  },
})

router.afterEach((to) => {
  let property = to.params?.id
  let updatedProperty = null
  if (property !== undefined) {
    updatedProperty = property.toLowerCase()
  }

  let title = getTitleFromNews(to.name?.toLowerCase())

  if (!title) {
    title = property ? getTitle(updatedProperty) : getTitle(to.name?.toLowerCase())
  }

  document.title = title
})

export default router
