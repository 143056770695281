<template>
  <TransitionGroup name="list" tag="div" class="news__content">
    <div class="news__info-card" v-for="card in list" :key="card.id" @click.stop="callRouter(card)">
      <div class="news__description">
        <div class="news__meetup-date">
          <p class="news__date">{{ card.data.day }}</p>
          <p class="news__month">{{ card.data.month }}</p>
        </div>
        <p class="news__title" v-html="card.data.content"></p>
      </div>

      <div class="news__image" :class="{ 'news__image--icon': card.data.icon }">
        <img
          v-if="card.data.href"
          :src="require(`@/assets/images/news/${card.data.href}`)"
          role="presentation"
          alt=""
          class="news__img"
        />
        <img
          v-if="card.data.icon"
          :src="require(`@/assets/images/news/${card.data.icon}`)"
          role="presentation"
          alt=""
          class="news__icon"
        />
      </div>
    </div>
    <slot></slot>
  </TransitionGroup>
</template>

<script setup>
import { useCallRouter } from "@/components/composables/useCallRouter"
import { useRouter } from "vue-router"
import { defineProps } from "vue"

defineProps({
  list: {
    type: Array,
    required: false,
  },
})

const router = useRouter()
const callRouter = (item) => useCallRouter(item, router)
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/news";

.list-enter-active,
.list-leave-active {
  transition: all 1.2s ease;
}
.list-leave-to,
.list-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
</style>
