<template>
  <footer class="digital-laboratory">
    <GradientBG
      :class="classElement"
      :animationAction="animationAction"
      :gradient="gradient"
      :gradientStyle="gradientStyle"
    />

    <h2 class="digital-laboratory__title heading-h1">
      ПСБ цифровая <br />
      лаборатория
    </h2>

    <div class="digital-laboratory__text" v-if="footerText">
      <div class="digital-laboratory__psb" @click.stop="goToPage()">© ПСБ</div>

      <a href="mailto:it@psbdigital.ru" class="digital-laboratory__link"> Почта </a>
    </div>
  </footer>
</template>

<script setup>
import { ref, defineProps } from "vue"
import { useGradient } from "./composables/useGradient"

defineProps({
  footerText: {
    type: Boolean,
    default: false,
  },
  gradientStyle: {
    type: Object,
    required: false,
  },
  gradient: {
    type: Boolean,
    default: false,
  },
})

let animationAction = ref()
let classElement = ref("gradientDigitalLab")

useGradient({ animationAction, classElement })

const goToPage = () => {
  window.open("https://www.psbank.ru", "_blank")
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/digital-laboratory";
</style>
