<template>
  <div class="container">
    <h1 class="news__title-h1">Новости</h1>

    <template v-if="eventList.length">
      <h2 class="news__title-h2">Мероприятия</h2>

      <EventComponent v-model:settings="settings" :list="eventList" class="news__section" />

      <h2 class="news__title-h2">Новости</h2>
      <NewsComponent :list="newsList"></NewsComponent>

      <button class="news__btn" @click.stop="router.push({ name: 'News' })">
        Смотреть все новости и мероприятия
      </button>
    </template>

    <template v-else>
      <NewsComponent :list="newsList"></NewsComponent>

      <button class="news__btn" @click.stop="router.push({ name: 'News' })">
        Смотреть все новости
      </button>
    </template>
  </div>
</template>

<script setup>
import EventComponent from "@/components/ListComponents/EventComponent.vue"
import NewsComponent from "@/components/ListComponents/NewsComponent.vue"
import { ref, onMounted } from "vue"
import { useRouter } from "vue-router"
import newsData from "@/assets/data/news.json"
import eventsData from "@/assets/data/events.json"

const { news } = newsData
const { events } = eventsData

const settings = ref({
  openedCard: 1,
})

const router = useRouter()

let eventList = events
let newsList = ref([])

const fillOfNews = () => {
  let clientWidth = document.documentElement.clientWidth
  let shownNews = 3
  let sortedNews = news
  if (clientWidth < 1024) {
    newsList.value = sortedNews.slice(0, shownNews + 1)
  } else {
    newsList.value = sortedNews.slice(0, shownNews)
  }
}

onMounted(() => {
  fillOfNews()
  window.addEventListener("resize", () => fillOfNews())
})
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  width: 33.5rem;
  padding-bottom: 2.4rem;

  @include mq(768) {
    width: 68.8rem;
  }

  @include mq(1024) {
    width: auto;
    padding: 0 4rem 8rem;
  }
  @include mq(1440) {
    padding: 0 calc(50% - 64.5rem) 12rem;
  }
}

.news {
  &__title-h2 {
    font-weight: 500;
    font-size: 3rem;
    line-height: 110%;
    letter-spacing: -0.064rem;
    padding: 4.8rem 0 2.4rem;

    @include mq(768) {
      font-size: 5.6rem;
      padding: 8rem 0 5rem;
    }

    @include mq(1024) {
      line-height: 90%;
      letter-spacing: -0.096rem;
    }

    @include mq(1440) {
      font-size: 6.4rem;
      line-height: 6.4rem;
    }
  }

  &__title-h1 {
    font-weight: 500;
    font-size: 4.8rem;
    line-height: 90%;
    letter-spacing: -0.32rem;
    padding: 8rem 0 4rem;
    text-align: center;

    @include mq(768) {
      font-size: 9.6rem;
      letter-spacing: -0.576rem;
      padding: 10rem 0 4rem;
    }

    @include mq(1024) {
      line-height: 100%;
    }

    @include mq(1440) {
      font-size: 15rem;
      padding: 14rem 0 6rem;
      letter-spacing: -0.96rem;
    }
  }

  &__btn {
    margin-top: 3rem;
    border: 0.133rem solid rgba(19, 20, 75, 0.2);
    border-radius: 1.6rem;
    width: 100%;
    height: 6.4rem;
    color: rgb(19, 20, 75);
    background: var(--bg);
    font-size: 1.6rem;
    letter-spacing: -0.048rem;
    cursor: pointer;

    @include mq(1024) {
      font-size: 2.4rem;
    }
  }

  &__section {
    @include mq(1024) {
    }
    @include mq(1440) {
      overflow-x: clip !important;
      overflow-y: clip !important;
    }
  }
}
</style>
