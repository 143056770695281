export const getTitle = (routeName) => {
  return {
    home: "ПСБ цифровая лаборатория",
    news: "Новости",
    events: "Митап",
    reports: "Доклад",
    frontend: "Вакансия Frontend-разработчик",
    java: "Вакансия Java-разработчик",
    analytic: "Вакансия Системный аналитик",
    net: "Вакансия Net",
    qa: "Вакансия Старший инженер по тестированию",
    opensource: "Вакансия Администратор OpenSource",
    postgre: "Вакансия Администратор PostgreSQL",
    devops: "Вакансия Devops",
    engineerqa: "Вакансия Инженер по нагрузочному тестированию",
    sqa: "День докладов",
    prod: "Подкаст ПСБ",
    media: "Мы в СМИ",
    substitution: "Импортозамещение",
    students: "Первые митапы для студентов",
    yaroslavl: "Митап в Ярославле",
    yar: "Пришлашаем на митап ПСБ в Ярославле",
    school: "ПСБ запустил онлайн-школу",
    sevastopol: "Митап в Севастополе",
    analyst: "Конференция Analyst Days",
    "devops-novyy-podkhod-v-razrabotke": "DevOps — новый подход в разработке",
    "da-da-eto-psb-agile-sreda": "Да-да, это ПСБ Agile среда!",
    intelligence: "Искусственный интеллект",
    days: "Analyst-days/18",
    "js-conference": "Конференция Сибирь.JS",
    "mono-app": "приложение «Мой Бизнес»",
    "it-event": "ИТ-событие Северо-запада",
    "what-agile-fault": "В чем виноват Agile?",
    "artyom-domashev-visiting-bagreport": "Артём Домашев в гостях у Багрепорта",
    "psb-na-forume-inzhenery-budushchego": "ПСБ на форуме «Инженеры будущего – 2024»",
    "kak-modulnoye-testirovaniye-sokrashchayet-zatraty-programmista":
      "Как модульное тестирование сокращает затраты программиста",
    NotFound: "Страница не найдена",
  }[routeName]
}
