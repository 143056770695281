<template>
  <div class="event-wrapper" ref="wrapper">
    <div
      class="main__wrapper"
      v-for="(card, index) in list"
      :key="card"
      @click.stop="clickOnCard(card, index)"
    >
      <div class="main__event" ref="mainEvent">
        <div class="main__event_info" ref="info">
          <div class="main__event_meetup-date">
            <p class="main__event_date" v-html="card.data.day"></p>
            <div class="main__event_month-wrapper">
              <span class="main__event_month-text" v-html="card.data.month"></span>
            </div>
          </div>
          <p class="main__event_text">
            <span v-html="card.data.content"></span>
            <img src="@/assets/images/news/union.svg" alt="arrow" class="main__event_img" />
          </p>
        </div>
        <div class="main__event_city" ref="city">
          <img
            v-if="card.data.href"
            :src="require(`@/assets/images/news/${card.data.href}`)"
            alt="arrow"
            class="main__event_city_img"
          />
          <div class="overlay"></div>
          <div class="main__event_arrow" ref="arrow"></div>
          <div class="main__event_style">
            <div class="main__event_city-month-wrapper" ref="month">
              <p class="main__event_city-text" v-html="card.data.dayMonth"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onMounted, nextTick } from "vue"
import { useRouter } from "vue-router"
import { useCallRouter } from "@/components/composables/useCallRouter"
import { useMouseScroll } from "@/components/composables/useMouseScroll"
const router = useRouter()

const emit = defineEmits(["update:settings"])
const props = defineProps({
  list: {
    type: Object,
    required: true,
  },
  settings: {
    type: Object,
    required: false,
  },
})

let clientWidth = ref(document.documentElement.clientWidth)
const arrow = ref(null)
const city = ref(null)
const info = ref(null)
const month = ref(null)
const wrapper = ref(null)
const mainEvent = ref(null)

const callRouter = (item) => useCallRouter(item, router)

const updatedClasses = () => {
  if (clientWidth.value > 1023) {
    [...info.value].forEach((info) => {
      info.classList.add("reset")
      info.classList.remove("active")
    })
  }

  [...city.value, ...arrow.value, ...month.value].forEach((el) => {
    el.classList.remove("active")
  })
}

const clickOnCard = (card, index) => {
  if (clientWidth.value < 1024) {
    callRouter(card)
    return
  }
  let currentInfo = info.value[index]
  if (currentInfo.classList.contains("active")) {
    callRouter(card)
  }
  updatedClasses()
  emit("update:settings", { openedCard: index + 1 })
}

let openCard = (value) => {
  let active = 0
  let openedCard = null

  if (value !== undefined) {
    openedCard = value.openedCard
    updatedClasses()
    active = openedCard - 1
    info.value[active].classList.remove("reset")
  }

  let infoNode = info.value[active]
  let cityNode = city.value[active]
  let arrowNode = arrow.value[active]
  let monthNode = month.value[active]

  if (!infoNode.classList.contains("active")) {
    infoNode.classList.add("active")
    cityNode.classList.add("active")
    arrowNode.classList.add("active")
    monthNode.classList.add("active")

    let isClass = wrapper.value?.classList.contains("overflow")
    if (clientWidth.value > 1023 && !!openedCard && !isClass) {
      setTimeout(() => {
        wrapper.value?.classList.add("overflow")
      }, 1500)
    }
  }
}

watch(
  () => props.settings,
  (value) => {
    nextTick(() => {
      openCard(value)
    })
  },
  {
    immediate: true,
  },
)

const onEventListener = () => {
  window.addEventListener("resize", () => {
    clientWidth.value = document.documentElement.clientWidth
  })
}

onMounted(() => {
  onEventListener()
  useMouseScroll([wrapper.value])
})
</script>

<style lang="scss" scoped>
.main {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    padding: 6.5rem 0 4rem;
    color: var(--color-white);
    font-size: 4.8rem;
    line-height: 100%;
    letter-spacing: -0.096rem;
    font-weight: 500;

    @include mq(768) {
      font-size: 7.8rem;
      letter-spacing: -0.048rem;
    }
    @include mq(1024) {
      font-size: 12rem;
      padding: 22rem 0 13rem;
    }
    @include mq(1440) {
      font-size: 15rem;
    }
  }
  &__sub-title {
    color: var(--color-white);
    font-size: 3rem;
    line-height: 110%;
    letter-spacing: -0.064rem;
    padding-bottom: 2.4rem;
    font-weight: 500;

    @include mq(768) {
      font-size: 4.8rem;
      letter-spacing: -0.048rem;
    }
    @include mq(1024) {
      font-size: 8rem;
    }
    @include mq(1440) {
      font-size: 10rem;
    }
  }
  &__wrapper {
    margin-right: 1rem;
    scroll-snap-align: start;

    @include mq(1024) {
      margin-right: 2rem;
      height: 41.4rem;
    }
  }
  &__event {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-radius: 1.6rem;
    box-shadow: 0 0.667rem 2.667rem 0 rgba(0, 0, 0, 0.07);
    background: var(--color-white);
    width: 32.2rem;
    height: 24rem;

    @include mq(768) {
      width: 53rem;
      height: 26rem;
    }
    @include mq(1024) {
      width: auto;
      height: 41.4rem;
    }

    &_meetup-date {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_info {
      padding: 3rem 0.5rem 3rem 1.6rem;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include mq(768) {
        padding: 2rem;
        width: 41.4rem;
      }
      @include mq(1024) {
        padding: 0;
        width: 0;
        transition:
          width 1.2s ease 0.7s,
          padding 1.2s ease 0.7s,
          opacity 0.5s ease 1.5s;
      }
      &.active {
        @include mq(768) {
          padding: 2rem;
        }
        @include mq(1024) {
          padding: 4rem;
          width: 43.3rem;
        }
      }
      &.reset {
        opacity: 0;
        transition:
          width 1.2s ease 0.7s,
          padding 1.2s ease 0.7s,
          opacity 1s ease;
      }
    }

    &_city {
      position: relative;
      height: 100%;
      width: 46.51%;

      @include mq(768) {
        width: 63.82%;
      }
      @include mq(1024) {
        width: 12.8rem;
        transition: all 1.2s ease;
      }

      &.active {
        @include mq(1024) {
          width: 41.4rem;
        }
      }

      &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1.6rem;
      }
      &-text {
        line-height: 120%;
        letter-spacing: -0.032rem;

        @include mq(1440) {
          font-size: 1.6rem;
          letter-spacing: -0.048rem;
          line-height: 140%;
        }
      }
    }
    &_text {
      font-size: 1.8rem;
      line-height: 120%;
      letter-spacing: -0.032rem;

      @include mq(768) {
        font-size: 2rem;
        letter-spacing: -0.048rem;
      }

      @include mq(1024) {
        font-size: 3.2rem;
      }
    }
    &_img {
      padding-left: 1rem;

      @include mq(1024) {
        display: none;
      }
    }
    &_date {
      font-size: 4.8rem;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.096rem;

      @include mq(768) {
        letter-spacing: -0.048rem;
      }

      @include mq(1024) {
        font-size: 15rem;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.96rem;
      }
    }
    &_month-wrapper {
      border-radius: 3.6rem;
      background: rgb(24, 24, 34);
      padding: 0.2rem 0.8rem 0.2rem 0.8rem;
      line-height: 120%;
      letter-spacing: -0.048rem;

      @include mq(1024) {
        padding: 0.25rem 3.1rem 0.25rem 3.1rem;
      }
    }
    &_city-month-wrapper {
      position: relative;
      background: white;
      color: black;
      border-radius: 4rem;
      padding: 0.45rem 1rem 0.45rem;
      transform: translate(-50%, -50%) rotate(-90deg);
      transition: left 1.2s ease;

      @include mq(768) {
        left: 14.5rem;
      }
      @include mq(1024) {
        backdrop-filter: blur(40px);
        background: rgba(255, 255, 255, 0.2);
        color: white;
        padding: 1rem 1.6rem;
        left: 0;
      }
      &.active {
        @include mq(1024) {
          background: var(--color-white);
          color: rgb(24, 24, 34);
          left: 28.5rem;
        }
      }
    }
    &_month-text {
      color: var(--color-white);
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.032rem;

      @include mq(1024) {
        font-size: 3rem;
        letter-spacing: -0.048rem;
      }
      @include mq(1440) {
        font-size: 2.4rem;
      }
    }
    &_arrow {
      position: absolute;
      top: 4rem;
      right: 4rem;
      opacity: 0;
      transition: opacity 1s ease 0.5s;

      @include mq(1024) {
        background: url("@/assets/images/news/arrow.svg") no-repeat;
        height: 5rem;
        width: 5rem;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}

.event-wrapper {
  display: flex;
  justify-content: flex-start;
  scroll-snap-type: x mandatory;
  margin-right: -20px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: pointer;
  border-radius: 1.6rem 0 0 1.6rem;
  -ms-overflow-style: none; /* Скрываем scrollbar для IE, Edge и Firefox IE и Edge */
  scrollbar-width: none; /* Скрываем scrollbar для IE, Edge и Firefox Firefox */
  &::-webkit-scrollbar {
    /* Скрываем scrollbar для Chrome, Safari и Opera */
    display: none;
  }

  @include mq(768) {
    margin-right: -30px;
  }
  @include mq(1024) {
    margin-right: -4rem;
    overflow-x: clip;
    overflow-y: clip;
  }

  @include mq(1440) {
    margin-right: 0;
  }

  &.overflow {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 42.754%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 2rem;
  position: absolute;
  top: 0;
  width: 100%;
  height: inherit;
}

.main__event_style {
  position: relative;
  bottom: 6rem;
  left: 5.2rem;
  width: fit-content;

  @include mq(1024) {
    bottom: 8rem;
    left: 7rem;
  }
}
</style>
