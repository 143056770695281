import reportsData from "@/assets/data/reports.json"
import eventsData from "@/assets/data/events.json"
import meetupsData from "@/assets/data/meetups.json"
import vacanciesData from "@/assets/data/vacancies.json"

export const callBeforeEnter = (message, to, next) => {
  const data = {
    reports: reportsData.reports,
    events: eventsData.events,
    meetups: meetupsData.meetups,
    vacancies: vacanciesData.vacancies,
  }[message]

  const exist = data.find((el) => el.name.toLowerCase() === to.params.id.toLowerCase())

  if (!exist) {
    next({
      path: "/:catchAll(.*)",
      name: "NotFound",
      params: { catchAll: to.path.substring(1) },
    })
  } else next()
}
