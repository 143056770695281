export const useGoToPage = (link) => {
  switch (link) {
    case "Habr":
      window.open("https://habr.com/ru/companies/psb/articles/", "_blank")
      break
    case "Rutube":
      window.open("https://rutube.ru/plst/232091", "_blank")
      break
    case "Telegram":
      window.open("https://t.me/+gMDxGmWOkgtjMWZi", "_blank")
      break
    case "Email":
      window.open("mailto:it@psbdigital.ru", "_blank")
      break
    case "Recommend":
      window.open("https://work.psblab.ru", "_blank")
      break
    case "Psb":
      window.open("https://www.psbank.ru", "_blank")
      break
    case "Vacancy":
      window.open("https://hh.ru/employer/6591#it", "_blank")
      break
    case "Events":
      window.open("https://psb-meetup.ru", "_blank")
      break
  }
}
