<template>
  <section class="education js-education">
    <h2 class="education__heading heading-h2">Развивайся <br />с&nbsp;нами</h2>
    <div class="education__subtitle heading-h5">
      Готовим новые кадры и&nbsp;помогаем <br />
      расти сотрудникам
    </div>
    <div class="education__card">
      <div class="js-education-corp">
        <div class="education__hero education__hero--corp js-education-hero-corp">
          <video
            class="education__video"
            autoplay
            loop
            muted
            playsinline
            poster="../assets/images/education/education-corp.jpg"
          >
            <source type="video/mp4" src="../assets/video/education/education-corp-(1080p).mp4" />
          </video>
          <div class="education__card-text-wrapper">
            <h3 class="education__card-title heading-h2 js-education-title-corp">
              Корпоративное <br />
              обучение
            </h3>
            <h3 class="education__card-subtitle heading-h5 js-education-title-corp">
              В&nbsp;ПСБ можно учиться с&nbsp;первого дня работы
            </h3>
          </div>
        </div>

        <div class="education__list education-card">
          <div class="education-card__item">
            <div class="education-card__title heading-h4">Внутри банка</div>
            <div class="education-card__content">
              <div class="education-card__content-inner">
                <div class="education-card__text">
                  Начинается с&nbsp;адаптации для новых сотрудников и&nbsp;продолжается по&nbsp;мере
                  развития в&nbsp;профессии
                </div>
                <ul class="education-card__list">
                  <li class="education-card__list-item">Адаптация</li>
                  <li
                    class="education-card__list-item education-card__list-item--cicle education-card__list-item--arrow"
                  ></li>
                  <li class="education-card__list-item">Развитие в&nbsp;должности</li>
                  <li
                    class="education-card__list-item education-card__list-item--cicle education-card__list-item--arrow"
                  ></li>
                  <li class="education-card__list-item">Стратегическое развитие</li>
                </ul>
                <div class="education-card__subtitle heading-h5">Какие есть форматы:</div>
                <ul class="education-card__list">
                  <li class="education-card__list-item">Онлайн-курсы</li>
                  <li class="education-card__list-item">Онлайн и&nbsp;офлайн-тренинги</li>
                  <li class="education-card__list-item">Митапы</li>
                  <li class="education-card__list-item">Воркшопы</li>
                  <li class="education-card__list-item">Тематические клубы</li>
                  <li class="education-card__list-item">Марафоны</li>
                </ul>
                <div class="education-card__text">
                  Выступают как эксперты из&nbsp;ПСБ, так и&nbsp;приглашенные специалисты
                </div>
                <div class="education-card__text">
                  Мы&nbsp;приветствуем инициативу от&nbsp;сотрудников&nbsp;— всегда готовы провести
                  обучение по&nbsp;запросу
                </div>
              </div>
            </div>
          </div>
          <div class="education-card__item">
            <div class="education-card__title heading-h4">На&nbsp;внешних площадках</div>
            <div class="education-card__content">
              <div class="education-card__content-inner">
                <div class="education-card__text">
                  Любые варианты обучения, которые помогут сотруднику профессионально вырасти
                </div>
                <ul class="education-card__list">
                  <li class="education-card__list-item">Программы повышения квалификации</li>
                  <li class="education-card__list-item">Курсы профессиональной подготовки</li>
                  <li class="education-card__list-item">Тренинги</li>
                  <li class="education-card__list-item">Семинары</li>
                  <li class="education-card__list-item">Конференции</li>
                  <li class="education-card__list-item">Форумы</li>
                </ul>
                <div class="education-card__text">
                  Можно самостоятельно подбирать нужные программы. Если они полезны компании
                  и&nbsp;сотруднику&nbsp;— банк их&nbsp;оплатит
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="education__card js-education-school">
          <div class="education__hero education__hero--school js-education-hero-school">
            <video
              class="education__video"
              autoplay
              loop
              muted
              playsinline
              poster="../assets/images/education/education-school.jpg"
            >
              <source
                type="video/mp4"
                src="../assets/video/education/education-school-(1080p).mp4"
              />
            </video>
            <div class="education__card-text-wrapper">
              <h3 class="education__card-title heading-h2 js-education-title-school">
                Школа <br />
                цифровых ролей
              </h3>
              <h3 class="education__card-subtitle heading-h5 js-education-title-school">
                Бесплатное обучение для студентов вузов и&nbsp;подготовка ИТ-специалистов
              </h3>
            </div>
          </div>
          <div class="education__list education-card">
            <div class="education-card__item education-card__item--accordion">
              <div class="education-card__title heading-h4">Школа функционального тестирования</div>
              <div class="education-card__content">
                <div class="education-card__content-inner">
                  <div class="education-card__text">
                    Инженер по&nbsp;тестированию (он&nbsp;же QA-тестировщик, или QA-engineer)
                    продумывает, <br />
                    что и&nbsp;где может сломаться, прогнозирует сбои и&nbsp;находит ошибки
                  </div>
                  <div class="education-card__subtitle heading-h5">
                    За&nbsp;что отвечает тестировщик в&nbsp;компании:
                  </div>
                  <ul class="education-card__list">
                    <li class="education-card__list-item">Бесперебойная работа сервиса</li>
                    <li class="education-card__list-item">Поиск ошибок в&nbsp;интерфейсах</li>
                  </ul>
                  <div class="education-card__subtitle heading-h5">Длительность</div>
                  <ul class="education-card__list">
                    <li class="education-card__list-item">3,5 месяца обучения</li>
                    <li class="education-card__list-item education-card__list-item--cicle">+</li>
                    <li class="education-card__list-item">3 месяца стажировки</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="education-card__item education-card__item--accordion">
              <div class="education-card__title heading-h4">
                Школа backend-разработки на&nbsp;C#
              </div>
              <div class="education-card__content">
                <div class="education-card__content-inner">
                  <div class="education-card__text">
                    Включает базовый и&nbsp;специализированный курсы по&nbsp;C#/.Net
                  </div>
                  <div class="education-card__text">
                    Разработчик создает программы и&nbsp;сервисы, а&nbsp;еще поддерживает стабильную
                    работу продуктов
                  </div>
                  <div class="education-card__subtitle heading-h5">
                    За что отвечает разработчик ПО:
                  </div>
                  <ul class="education-card__list">
                    <li class="education-card__list-item">Оценка задачи на&nbsp;выполнимость</li>
                    <li class="education-card__list-item">Первичная оценка ресурсов</li>
                    <li class="education-card__list-item">Оптимизация задачи</li>
                    <li class="education-card__list-item">Разработка</li>
                    <li class="education-card__list-item">Сопровождение продукта</li>
                    <li class="education-card__list-item">
                      Подготовка документации к&nbsp;доработкам
                    </li>
                    <li class="education-card__list-item">Качество кода</li>
                  </ul>
                  <div class="education-card__subtitle heading-h5">Длительность</div>
                  <ul class="education-card__list">
                    <li class="education-card__list-item">
                      2&nbsp;месяца обучения на&nbsp;базовом курсе
                    </li>
                    <li class="education-card__list-item education-card__list-item--cicle">+</li>
                    <li class="education-card__list-item">
                      4&nbsp;месяца на&nbsp;специализированном курсе по&nbsp;C#/.Net
                    </li>
                    <li class="education-card__list-item education-card__list-item--cicle">+</li>
                    <li class="education-card__list-item">3&nbsp;месяца стажировки</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="education-card__item education-card__item--accordion">
              <div class="education-card__title heading-h4">Школа системного анализа</div>
              <div class="education-card__content">
                <div class="education-card__content-inner">
                  <div class="education-card__text">
                    Системный аналитик разрабатывает требования к&nbsp;программному обеспечению.
                    <br />
                    Он&nbsp;составляет список задач и&nbsp;ясно доносит их&nbsp;команде
                  </div>
                  <div class="education-card__subtitle heading-h5">
                    За что отвечает системный аналитик:
                  </div>
                  <ul class="education-card__list">
                    <li class="education-card__list-item">Проектирование ПО</li>
                    <li class="education-card__list-item">Составление технического задания</li>
                    <li class="education-card__list-item">Сбор требований</li>
                    <li class="education-card__list-item">Составление схем</li>
                    <li class="education-card__list-item">Валидацию тест-кейсов</li>
                    <li class="education-card__list-item">Декомпозицию задач</li>
                    <li class="education-card__list-item">Коммуникацию с&nbsp;командами</li>
                  </ul>
                  <div class="education-card__subtitle heading-h5">Длительность</div>
                  <ul class="education-card__list">
                    <li class="education-card__list-item">3,5 месяца обучения</li>
                    <li class="education-card__list-item education-card__list-item--cicle">+</li>
                    <li class="education-card__list-item">3&nbsp;месяца стажировки</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted } from "vue"
import { gsap } from "@/helpers/gsap"
import { refreshScrollTriggerByElement } from "@/helpers"

function init() {
  const educationContainerEl = document.querySelector(".js-education")
  if (!educationContainerEl) {
    return
  }

  const educationHeadingEl = educationContainerEl.querySelectorAll(
    ".education__heading, .education__subtitle",
  )

  const educationCorpContainerEl = educationContainerEl.querySelector(".js-education-corp")
  const educationCorpEl = educationCorpContainerEl.querySelector(".js-education-hero-corp")
  const titlesCorpEl = educationCorpContainerEl.querySelectorAll(".js-education-title-corp")

  const educationSchoolContainerEl = educationContainerEl.querySelector(".js-education-school")
  const educationSchoolEl = educationSchoolContainerEl.querySelector(".js-education-hero-school")
  const titlesSchoolEl = educationSchoolContainerEl.querySelectorAll(".js-education-title-school")

  const accordionsEl = Array.from(
    educationContainerEl.querySelectorAll(".education-card__item--accordion"),
  )

  if (accordionsEl.length) {
    accordionsEl.forEach((accordion) => {
      accordion.addEventListener("click", (event) => {
        if (!event.target.classList.contains("education-card__title")) return

        accordion.classList.toggle("_open")
      })
    })
  }

  function initializeAnimation() {
    // заголовки Блока
    gsap.from(educationHeadingEl, {
      autoAlpha: 0,
      scrollTrigger: {
        trigger: educationHeadingEl,
        start: "top 80%",
        end: "top 30%",
        scrub: 1,
      },
    })

    // заголовки Корпоративное обучение
    gsap.from(titlesCorpEl, {
      autoAlpha: 0,
      scrollTrigger: {
        trigger: educationCorpEl,
        start: "top 50%",
        end: "clamp(center +=300px)",
        scrub: 1,
      },
    })

    gsap.to(educationCorpEl, {
      width: "100%",
      height: () => window.innerHeight + 110,
      maxWidth: "100%",
      borderRadius: 0,
      scrollTrigger: {
        trigger: educationCorpEl,
        start: "top 30%",
        end: "clamp(top top)",
        // end: "clamp(center +=300px)",
        scrub: 1,
      },
    })

    // скрыть Корпоративное обучение
    gsap.to(educationCorpEl, {
      opacity: 0,
      scrollTrigger: {
        trigger: educationSchoolEl,
        start: "top bottom",
        end: "+=500",
        scrub: 1,
      },
    })

    // заголовки школы
    gsap.from([...titlesSchoolEl], {
      autoAlpha: 0,
      scrollTrigger: {
        trigger: educationSchoolEl,
        start: "top 50%",
        end: "clamp(center +=300px)",
        scrub: 1,
      },
    })

    gsap.to(educationSchoolEl, {
      width: "100%",
      height: () => window.innerHeight + 110,
      maxWidth: "100%",
      borderRadius: 0,
      scrollTrigger: {
        trigger: educationSchoolEl,
        // start: "center 40%",
        // end: "clamp(bottom center)",
        start: "top 30%",
        end: "clamp(top top)",
        scrub: 1,
      },
    })
  }

  initializeAnimation()

  refreshScrollTriggerByElement(educationContainerEl)
  refreshScrollTriggerByElement(educationCorpEl)
  refreshScrollTriggerByElement(educationSchoolEl)
}

onMounted(() => {
  init()
  // isMobile() && ScrollTrigger.normalizeScroll(true);
})
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/education";
@import "@/assets/styles/components/education-card";
</style>
